import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Uploader, { ThumbnailDisplay } from '@cimpress-technology/react-platform-uploader';
import { useSelector } from 'react-redux';
import { AppState } from '../store/store';
import { Loading } from './loading';

export const initialLogo = {
  id: '',
  href: '',
  contentType: ''
};

const supportedContentType = ['image/jpeg', 'image/png', 'image/svg+xml'];

export const LogoUploader: React.FC<LogoProps> = props => {
  const { t } = useTranslation();
  const { auth } = useSelector((state: AppState) => state);
  const { accessToken } = auth;
  const [uploading, setUploading] = useState(false);
  const messages = { dragAndDropDocument: t('dragAndDrop'), or: t('or'), chooseFile: t('browseFiles') };
  const uploadedFile = props?.logoSelected?.id ? props.logoSelected : { href: props.selectedLogo };

  const changeHandler = files => {
    setUploading(false);
    if (files.length > 1) {
      throw new Error(t('statusMessages.multiFileSelectionNotSupported'));
    }
    if (!supportedContentType.includes(files[0].type)) {
      throw new Error(t('statusMessages.unsupportedContentType'));
    }
    const file: UploadedFile = { id: files[0].id, href: files[0].url, contentType: files[0].type };
    props.onChange(file);
  };

  const removeLogo = () => {
    if (props.selectedLogo) {props.onDeletingLogo();} else {props.onChange(initialLogo);}
  };

  return uploading ? <Loading/>
    : <div style={{ width: '100%' }}>
      <div>
        <Uploader
          onUploadFileSuccess={changeHandler}
          onDelete={removeLogo}
          provideThumbnails={true}
          type='standard'
          uploadTenant="default"
          accessToken={accessToken}
          messages = {messages}
          deleteAfterDays = {'1'}
          onUploadFileStart={() => setUploading(true)}
          onUploadFileError = {error => props.onUploadFileError(error)}
        />
      </div>
      <div>
        { (props?.logoSelected?.id || props.selectedLogo) && <ThumbnailDisplay
          tenant="default"
          uploads={[uploadedFile]}
          onDelete={removeLogo}
          accessToken={accessToken} >
        </ThumbnailDisplay> }
      </div>
    </div>;
};

interface LogoProps {
  logoSelected: UploadedFile;
  onChange: Function;
  onUploadFileError: Function;
  selectedLogo: string;
  onDeletingLogo: Function;
}
export interface UploadedFile{
  id: string;
  href: string;
  contentType: string;
}
