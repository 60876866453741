import { AuthState, AuthActionTypes } from './types';
import { AuthActions } from '../storeActions';

const initialState: AuthState = {
  accessToken: '',
  profile: {
    'sub': '', // eslint-disable-line @typescript-eslint/camelcase
    'email': '',
    'name': '',
    'https://claims.cimpress.io/account': ''
  },
  loading: false,
  error: null
};

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case AuthActions.FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case AuthActions.REFRESH_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      };
    case AuthActions.REFRESH_PROFILE:
      return {
        ...state,
        profile: action.profile
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        profile: {
          'email': '',
          'name': '',
          'sub': '',
          'https://claims.cimpress.io/account': ''
        },
        accessToken: '',
        error: null
      };
    default:
      return state;
  }
}
