import React, { useState, useEffect } from 'react';
import { TextField, colors } from '@cimpress/react-components';
import { BrandDetailsDto, Themes } from '../../components/hooks/commonTypes';
import { useTranslation } from 'react-i18next';
import { onPatchBrandThemes, onResetEditAction } from '../../store/brands/actions';
import { showSnackbar } from '../../store/snackbar/actions';
import { patchBrand } from '../../services/brands';
import { Loading } from '../../components/loading';
import { ColorPicker } from '../../components/colorPicker';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { cloneDeep } from 'lodash';
import { InlineEditHeader } from '../../components/inlineEditHeader';

interface BrandThemesProps {
  brand: BrandDetailsDto|null;
  onChange: (path: string, value: string) => void;
}

const defaultTheme: Themes = {
  default: {
    brandColors: {
      default: {
        base: colors.teal.base
      }
    }
  }
};

export const BrandThemes: React.FC<BrandThemesProps> = ({ brand }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState(brand?.themes || defaultTheme);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (brand?.themes) {
      setState(brand?.themes);
    }
  }, [brand]);

  const brandThemesMap = new Map();
  if (!brand) {
    return <>&nbsp;</>;
  }

  const onSave = async() => {
    try {
      brandThemesMap.set('/themes', state);
      setSaving(true);
      await patchBrand(accessToken, brandThemesMap, brand.brandId, false);
      setSaving(false);
      setEdit(false);
      dispatch(onResetEditAction(false));
      if (state) {
        dispatch(onPatchBrandThemes(state));
      }
      dispatch(showSnackbar(t('statusMessages.brandEditedSuccessfully'), undefined, 'success'));
    } catch (err) {
      setSaving(false);
      dispatch(showSnackbar(err || t('statusMessages.errorInEditingBrand'), undefined, 'danger'));
    }
  };

  const onColorChange = (color: string) => {
    const newState = cloneDeep(state);
    newState.default.brandColors.default.base = color;
    setState(newState);
  };

  const onColorInput = e => {
    const newState = cloneDeep(state);
    newState.default.brandColors.default.base = e.target.value;
    setState(newState);
  };
  
  const onEdit = () => {
    setEdit(true);
    dispatch(onResetEditAction(true));
  };

  const onCancel = () => {
    setEdit(false);
    setState(brand?.themes || defaultTheme);
    dispatch(onResetEditAction(false));
  };

  return saving ? <Loading/>
    : <div style={{ width: '100%' }}>
      <InlineEditHeader
        onEdit={onEdit}
        onSave={onSave}
        onCancel={onCancel}
        edit = {edit}
        title={t('brand.themes.colors.title')}
      />
      <div className='row'>
        <div className='col-md-6'>
          <TextField
            name={'baseColor'}
            label={t('placeholder.baseColor')}
            value={state?.default?.brandColors?.default?.base}
            onChange={onColorInput}
            disabled={!edit}
            rightAddon={
              edit ? <ColorPicker color={state?.default?.brandColors?.default?.base} onColorChange={onColorChange}/>
                : <div style={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '2px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  background: `${state.default.brandColors.default.base}`,
                  border: `1px solid ${colors.alloy}`,
                  borderLeft: 0,
                  marginLeft: 'auto',
                  cursor: 'pointer'
                }}/>
            }
          />
        </div>
      </div>
    </div>;
};
