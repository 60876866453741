import { CssLoader } from '@cimpress/react-components';
import { BrowserRouter as Router } from 'react-router-dom';

import { Home } from './pages/home';

// @ts-ignore
import { FullStory, Sentry } from '@cimpress-technology/react-reporting-redux';
import { MixpanelIntegration, MixpanelDevProjectId, MixpanelProductionProjectId, getEventTracker, TrackedRoute } from '@cimpress-technology/react-mixpanel';
import React, { useEffect } from 'react';
import { AppHeader } from './appHeader';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './store/store';
import * as authActions from './store/auth/actions';
import { Loading } from './components/loading';
import { BrandDetails } from './pages/brand';
import './styles/main.css';
import { loadAccounts } from './store/accounts/actions';
import { SnackbarNotification } from './components/snackbarNotification';

const eventTracker = getEventTracker('Brand Manager');

const App = () => {
  const { accessToken, profile } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();

  const loggedIn = !!accessToken;

  useEffect(() => {
    if (!accessToken) {
      dispatch(authActions.login());
    }
    if (accessToken) {
      dispatch(loadAccounts(accessToken));
    }
  }, [accessToken, dispatch]);

  if (!loggedIn) {
    return (<Loading/>);
  }

  const isProduction
      = process.env.REACT_APP_ENVIRONMENT_NAME === 'Production';

  return (
    <CssLoader>
      <div className="appShell">
        <MixpanelIntegration
          accessToken={accessToken}
          userProfile={profile}
          applicationName={'Brand Manager'}
          mixpanelProjectId={isProduction ? MixpanelProductionProjectId : MixpanelDevProjectId}
        />
        <Sentry
          sentryOptions={{
            dsn: 'https://a7aff13fd56b4606a69c3ea87dcdabec@o153206.ingest.sentry.io/5796697',
            blacklistUrls: ['http://localhost:8080', 'http://localhost:3000'],
            releaseVersion: 'untracked',
            enabled: isProduction
          }}
          selector={(state: any) => state.auth}
        />
        <FullStory
          fsOptions={{
            org: '97CT5', // This is the shared CT account
            allowLocalhost: false,
            debug: false
          }}
          selector={(state: any) => state.auth}
        />
        <SnackbarNotification />
        <Router>
          <AppHeader />
          <TrackedRoute exact path="/"
            trackEvent={eventTracker}
            title={'Home'}
            render={routerProps => (<Home
              {...routerProps}
            />
            )}/>
          <TrackedRoute exact path="/brands/:id"
            trackEvent={eventTracker}
            title={'Brand Details'}
            render={routerProps => (<BrandDetails
              {...routerProps}
            />
            )}/>
        </Router>
      </div>
    </CssLoader>
  );
};

export {
  App
};
