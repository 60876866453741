import { AccountsActionTypes, AccountsState } from './types';
import { AccountsActions } from '../storeActions';

const initialState: AccountsState = {
  accounts: null,
  loading: false,
  error: null
};

export function accountsReducer(state = initialState, action: AccountsActionTypes): AccountsState {
  switch (action.type) {
    case AccountsActions.LOAD_START:
      return {
        ...state,
        error: null,
        loading: true
      };
    case AccountsActions.LOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case AccountsActions.LOAD_SUCCESS:
      return {
        ...state,
        accounts: Object.fromEntries(action.accounts.map(account => [account.accountId, account])),
        error: null,
        loading: false
      };
    case AccountsActions.PATCH_ACCOUNT_BRAND_ID:
      if (state.accounts && state.accounts[action.accountId]) { // TODO: Should we handle the odd case where an account is updated that is not loaded?
        state.accounts[action.accountId].brandId = action.brandId;
      }
      return {
        ...state
      };
    default:
      return state;
  }
}
