import React from 'react';
import { Card, FlexBox } from '@cimpress/react-components';
import { BrandUsageLabel } from '../../components/brandUsageLabel';
import { NoImage } from '../../components/noImage';
import { dateFormatter } from '../../utils/dateTimeUtils';
import { ItemDto } from '../../components/hooks/commonTypes';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export interface BrandCardProps {
    brand: ItemDto;
    accountsCount?: number;
    userNames: Record<string, string>;
}

export const BrandCard: React.FC<BrandCardProps> = ({ brand, accountsCount, userNames }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToViewPage = (brandId: string) => history.push(`/brands/${brandId}`);

  if (!brand) {
    return <div className='col-md-4'>
          &nbsp;
    </div>;
  }

  return <div className='col-md-4'>
    <Card key={brand.brandId}>
      <div style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', height: '250px' }}>
        <div>
          <div className='brandName' onClick={() => goToViewPage(brand.brandId)} >
            <span>{brand.brandName}</span>
          </div>
        </div>
        <div onClick={() => goToViewPage(brand.brandId)} style={{ flexGrow: 100 }}>
          {brand?.header?.logo?.dataUri
            ? <img
              className ='brandLogo'
              src={brand.header.logo.dataUri}
              alt={brand.brandName}
            />
            : <FlexBox middle center>
              <div style={{ flexGrow: 1000 }}>
                <NoImage width = '100%'/>
              </div>
            </FlexBox> }
        </div>
        <div>
          <span>{t('lastEdited')} &nbsp;{ brand.modifiedAt ? dateFormatter(brand.modifiedAt) : (brand.createdAt && dateFormatter(brand.createdAt))}</span>
        </div>
        <div className='createdBy-overflow'>
          <span>{t('createdBy')}&nbsp;{ userNames[brand.createdBy] || brand.createdBy}</span>
        </div>
        <hr/>
        <div>
          <BrandUsageLabel count={accountsCount || 0} />
        </div>
      </div>
    </Card>
  </div>;
};
