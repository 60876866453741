/* eslint-disable no-throw-literal */
// @flow
import urlJoin from 'url-join';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */
const parseJSON = async (response: Response) => {
  if (response && response.status === 204) {
    return {
      status: response.status,
      ok: response.ok
    };
  }

  try {
    const json = await response.json();
    return {
      status: response.status,
      ok: response.ok,
      json
    };
  } catch {
    throw response;
  }
};

/**
 * Requests a URL, returning a promise. BaseURL is required.
 * If URL is not passed in, the baseURL will be used. This was done because the URL may contain weird things (e.g. regex) that can behave unexpectedly by url-join library.
 *
 * @param  {string} baseUrl   The base URL (e.g. https://myservice.com)
 * @param  {string} url       The URL we want to request (e.g. /api/v0/myRoute)
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @param serviceComment
 * @return {Promise}           The request promise
 */
export const makeRequest = async (baseUrl: string, url: string, options: RequestInit, serviceComment: string) => {
  if (!baseUrl) {
    throw {
      serviceComment: 'Base URL was not set.',
      statusCode: 0
    };
  }

  let fullUrl;
  if (!url) {
    fullUrl = baseUrl;
  } else {
    fullUrl = urlJoin(baseUrl, url);
  }

  try {
    const response = await parseJSON(await fetch(fullUrl, options));
    if (!response.ok) {
      throw {
        serviceComment,
        statusCode: response.status || 0,
        response: response.json || response
      };
    }

    return response.json;
  } catch (error: any) {
    throw {
      serviceComment,
      statusCode: error.status || 0,
      response: error.json || error
    };
  }
};

interface BuildOptionsOptions {
  method?: RequestInit['method'];
  body?: any;
  hal?: boolean;
  accessToken?: string;
  headerOverrides?: Record<string, string>;
}

export const buildOptions = ({ method, body, hal, accessToken, headerOverrides }: BuildOptionsOptions = {}): RequestInit => {
  const headers = new Headers({
    Accept: hal
      ? 'application/hal+json,application/json;q=0.9'
      : 'application/json',
    Authorization: `Bearer ${accessToken}`
  });

  if (method === 'POST' || method === 'PUT') {
    headers.append('Content-Type', 'application/json');
  }
  if (method === 'PATCH') {
    headers.append('Content-Type', 'application/json-patch+json');
  }

  for (const header of Object.entries(headerOverrides || {})) {
    headers.set(header[0], header[1]);
  }

  return {
    method: method || 'GET',
    mode: 'cors',
    headers,
    body
  };
};

export const buildPatchOption = details => {
  const arr = [
    details.logoUri && {
      op: 'add',
      path: 'header/logo/dataUri',
      value: details.logoUri
    },
    details.sideNavUri && {
      op: 'add',
      path: 'header/sideNav/logo/dataUri',
      value: details.sideNavUri
    }];

  return arr;
};

export const httpMethod = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
  PATCH: 'PATCH'
};
