import React, { useState } from 'react';
import { Alert, colors } from '@cimpress/react-components';
import { BrandDetailsDto, Header, LogoDto, SideNavDto } from '../../components/hooks/commonTypes';
import { NoImage } from '../../components/noImage';
import { useTranslation } from 'react-i18next';
import { LogoUploader, UploadedFile } from '../../components/logoUploader';
import { showSnackbar } from '../../store/snackbar/actions';
import { cloneDeep, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../components/loading';
import { patchBrand } from '../../services/brands';
import { onPatchBrandHeader, onResetEditAction } from '../../store/brands/actions';
import { AppState } from '../../store/store';
import { InlineEditHeader } from '../../components/inlineEditHeader';
import { getFile } from '@cimpress-technology/react-platform-uploader';

export interface BrandHeaderProps {
  brand: BrandDetailsDto;
}

const initialLogoValue = {
  id: '',
  href: '',
  contentType: ''
};

const uploadedFileDetails = {
  logo: initialLogoValue,
  sideNavLogo: initialLogoValue
};

export const BrandHeader: React.FC<BrandHeaderProps> = ({ brand }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState(brand?.header || {} as Header);
  const [logo, setLogo] = useState(uploadedFileDetails);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const tenant = 'default';
  const brandHeaderMap = new Map();

  if (!brand) {
    return <>&nbsp;</>;
  }
  const brandLogo = state?.logo?.dataUri;
  const sideNavLogo = state?.sideNav?.logo?.dataUri;

  const convertBase64 = async (uploadId: string) => {
    // @ts-ignore
    const file: any = await getFile(uploadId, tenant, accessToken);
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error: any) => {
        reject(error);
      };
    });
  };

  const setDataUri = async() => {
    const logoUri = logo.logo?.id && await convertBase64(logo.logo?.id);
    const sideNavUri = logo.sideNavLogo?.id && await convertBase64(logo.sideNavLogo?.id);
    const newState = cloneDeep(state);
    if (logoUri) {
      if (!newState?.logo) {newState.logo = {} as LogoDto;}
      // @ts-ignore
      newState.logo.dataUri = logoUri;
    }
    if (sideNavUri) {
      if (!newState?.sideNav) { newState.sideNav = {} as SideNavDto; }
      if (!newState.sideNav?.logo) { newState.sideNav.logo = {} as LogoDto; }
      // @ts-ignore
      newState.sideNav.logo.dataUri = sideNavUri;
    }
    
    brandHeaderMap.set('/header', newState);
    setState(newState);
    return brandHeaderMap;
  };

  const onSave = async() => {
    try {
      const map = await setDataUri();
      setSaving(true);
      await patchBrand(accessToken, map, brand.brandId, false);
      setSaving(false);
      dispatch(onPatchBrandHeader(map.get('/header')));
      setEdit(false);
      dispatch(onResetEditAction(false));
      dispatch(showSnackbar(t('statusMessages.brandEditedSuccessfully'), undefined, 'success'));
    } catch (err) {
      setSaving(false);
      dispatch(showSnackbar(t('statusMessages.errorInEditingBrand'), undefined, 'danger'));
    }
  };

  const onCheck = () => {
    const newState = cloneDeep(state);
    if (!newState?.sideNav) { newState.sideNav = {} as SideNavDto; }
    newState.sideNav.enabled = !state?.sideNav?.enabled ? true : !state.sideNav.enabled;
    if (!newState.sideNav?.logo) { newState.sideNav.logo = {} as LogoDto; }
    newState.sideNav.logo.dataUri = '';
    setLogo(state => ({ ...state, sideNavLogo: uploadedFileDetails.sideNavLogo }));
    setState(newState);
  };

  const onLogoChange = (logoDetails: UploadedFile) => {
    setLogo(state => ({ ...state, logo: logoDetails }));
  };

  const onSideDivLogoChange = (sideNavLogoDetails: UploadedFile) => {
    setLogo(state => ({ ...state, sideNavLogo: sideNavLogoDetails }));
  };
  
  const onUploadFileError = error => {
    dispatch(showSnackbar(get(error, 'message', t('statusMessages.errorInUploadingLogo')), undefined, 'danger'));
  };

  const onDeletingLogo = () => {
    const newState = cloneDeep(state);
    newState.logo.dataUri = '';
    setState(newState);
  };

  const onDeletingSideBarLogo = () => {
    const newState = cloneDeep(state);
    newState.sideNav.logo.dataUri = '';
    setState(newState);
  };

  const renderLogo = () => {
    return <div>
      {brandLogo
        ? <div>
          <div style={{ backgroundColor: colors.white, border: `1px solid ${colors.horizon}` }}>
            <img style= {{ maxWidth: '100%' }} src={brandLogo} alt={brand?.brandName || t('logo.notAvailable')} />
          </div>
        </div>
        : <NoImage/>}
    </div>;
  };

  const renderSideNavLogo = () => {
    if (!brand?.header?.sideNav?.enabled) {
      return <Alert type='info' message={t('brandPage.sideNavNotEnabled')} dismissible={false} />;
    }
    
    return <div>
      {sideNavLogo
        ? <>
          <div style={{ backgroundColor: colors.coal, border: `1px solid ${colors.cobalt.darkest}` }}>
            <img style= {{ maxWidth: '100%' }} src={sideNavLogo} alt={brand?.brandName || t('logo.notAvailable')} />
          </div>
        </>
        : <NoImage/>}
    </div>;
  };

  const onEdit = () => {
    setEdit(true);
    dispatch(onResetEditAction(true));
  };

  const onCancel = () => {
    setEdit(false);
    setState(brand?.header || {} as Header);
    setLogo(uploadedFileDetails);
    dispatch(onResetEditAction(false));
  };

  return saving ? <Loading/>
    : edit ? <>
      <InlineEditHeader
        onSave={onSave}
        onEdit={onEdit}
        onCancel ={onCancel}
        edit = {edit}
        title={t('header.logoTitle')}
      />
      <div className='row' style={{ display: 'grid' }}>
        <div className='col-md-6' >
          <LogoUploader
            logoSelected = {logo.logo}
            onChange={onLogoChange}
            selectedLogo={state?.logo?.dataUri}
            onDeletingLogo={onDeletingLogo}
            onUploadFileError = {err => onUploadFileError(err)} />
        </div>
        <div className='col-md-6'>
          <h6 style={{ margin: '5px' }}>
            <input type='checkbox'
              checked = {state?.sideNav?.enabled || false }
              onChange = {onCheck}
            /> &nbsp; {t('header.sidebar.title')}
          </h6>
          { state?.sideNav?.enabled
    && <div>
      <LogoUploader
        logoSelected = {logo.sideNavLogo}
        onChange = {onSideDivLogoChange}
        selectedLogo={state?.sideNav?.logo?.dataUri}
        onDeletingLogo={onDeletingSideBarLogo}
        onUploadFileError = {err => onUploadFileError(err)}/>
    </div>
          }
        </div>
      </div>
    </>
      : <div>
        <InlineEditHeader
          onEdit={onEdit}
          edit = {edit}
          title={t('header.logoTitle')}
        />
        <div className='row'>
          <div className='col-md-6'>
            {renderLogo()}
          </div>
        </div>
        <br/>
        <h4>{t('header.sidebar.title')}</h4>
        <div className='row'>
          <div className='col-md-6'>
            {renderSideNavLogo()}
          </div>
        </div>
      </div>;
};
