import { Button, Modal } from '@cimpress/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DeleteBrandModal: React.FC<DeleteBrandModalProps> = ({ show, onCancel, onConfirm }) => {
  const { t } = useTranslation();

  return <Modal
    status={'danger'}
    show={show}
    title={t('deleteBrandModal.title')}
    closeButton={true}
    onRequestHide={onCancel}
    footer={
      <>
        <Button variant={'link'} onClick={onCancel}>{t('cancel')}</Button>
        <Button variant={'default'} onClick={onConfirm}>{t('delete')}</Button>
      </>
    }
  >
    {t('deleteBrandModal.deletionConfirmationText')}
  </Modal>;
};

export {
  DeleteBrandModal
};

export interface DeleteBrandModalProps {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}
