import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import ReactDOM from 'react-dom';
import { colors } from '@cimpress/react-components';

const ColorPickerContainer = ({ children, onClose, left, top }) => (
  <div
    style={{
      position: 'absolute',
      top: top,
      right: 0,
      bottom: 0,
      left: left
    }}
  >
    <div
      onClick={ onClose }
      style={{
        cursor: 'pointer',
        position: 'absolute',
        width: '100%',
        height: '100%'
      }}
    />
    <div style={{ position: 'relative' }}>
      {children}
    </div>
  </div>
);

export const ColorPicker: React.FC<ColorPickerProps> = props => {
  const [displayColorPicker, setDisplayColorPicker] = useState({
    x: 0,
    y: 0,
    show: false
  });

  const handleClick = e => {
    setDisplayColorPicker({
      x: e.clientX,
      y: e.clientY,
      show: !displayColorPicker.show
    });
  };

  const handleClose = () => {
    setDisplayColorPicker({
      x: 0,
      y: 0,
      show: false
    });
  };

  const handleChange = color => {
    props.onColorChange(color.hex);
    handleClose();
  };

  return (
    <div>
      <div style={{
        width: '48px',
        height: '48px',
        borderRadius: '2px',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        background: `${props.color}`,
        border: `1px solid ${colors.alloy}`,
        borderLeft: 0,
        marginLeft: 'auto',
        cursor: 'pointer'
      }} onClick={handleClick} />
      { displayColorPicker.show
        ? <div>
          { ReactDOM.createPortal(
            <ColorPickerContainer onClose={ handleClose } left={displayColorPicker.x} top={displayColorPicker.y}>
              <SketchPicker
                color={props.color}
                onChangeComplete={ handleChange }
              />
            </ColorPickerContainer>,
            // @ts-ignore
            document.getElementById('colorPickerPortal')
          )}
        </div>
        : null }
    </div>
  );
};

interface ColorPickerProps {
    onColorChange: Function;
    color: string;
    
  }
