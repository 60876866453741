import { makeRequest, buildOptions } from './serviceHelpers';
import { HalJsonLinks, Paged } from './models/data';
import { get } from 'lodash';

export interface Account {
    accountId: string;
    brandId: string;
    status: string;
    name: string;
    description: string;
    cimpressOwned: string;
    essentialGroupId: string;
    _links: Partial<Pick<HalJsonLinks, 'self' | 'coamEssentialGroup' | 'auth0Connections'>>;
}

const endpointUrl: string = process.env.REACT_APP_ACCOUNTS_URL || '';
export const getAccounts = async (accessToken: any, include: string[] | null): Promise<Paged<Account, 'self'>> => {
  const options = buildOptions({ hal: true, accessToken });

  return makeRequest(
    endpointUrl,
    `/api/v1/accounts${include ? `?fields=${include.join(',')}&includeFields=true` : ''}`,
    options,
    'getting accounts'
  ).catch(err => {
    console.error(err);
    const errorResponse = get(
      err,
      'response.detail',
      'Error getting accounts'
    );
    console.error(errorResponse);
    return null;
  });
};

export const setAccountBrand = async (accessToken: string, accountId: string, brandId: string) => {
  const reqBody = {
    brandId
  };

  const headerOverrides = {
    'Content-Type': 'application/json'
  };
  // @ts-ignore
  const options = buildOptions({ method: 'PATCH', body: JSON.stringify(reqBody), hal: false, accessToken, headerOverrides });

  return makeRequest(
    endpointUrl,
    `/api/v1/accounts/${accountId}`,
    options,
    'patch account brand'
  )
    .then(() => {
      return;
    })
    .catch(err => {
      const errorResponse = get(
        err,
        'response.detail',
        'Error setting account brand'
      );
      console.error(errorResponse);
      throw errorResponse;
    });
};
