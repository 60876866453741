import React, { useState } from 'react';
import { TextField } from '@cimpress/react-components';
import { BrandDetailsDto, Contacts, ContactsDetails } from '../../components/hooks/commonTypes';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { showSnackbar } from '../../store/snackbar/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { patchBrand } from '../../services/brands';
import { onPatchBrandContacts, onResetEditAction } from '../../store/brands/actions';
import { Loading } from '../../components/loading';
import validator from 'validator';
import { InlineEditHeader } from '../../components/inlineEditHeader';

export interface BrandContactsProps {
  brand: BrandDetailsDto|null;
}

export const BrandContacts: React.FC<BrandContactsProps> = ({ brand }) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState(brand?.contacts || {} as Contacts);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state: AppState) => state.auth);
  const brandContactDetails = new Map();

  if (!brand) {
    return <>&nbsp;</>;
  }

  const isValid = (email?: string) => {
    if (!email) {return true;}
    return validator.isEmail(email);
  };

  const onAutomationContactChange = e => {
    const newState = cloneDeep(state);
    if (!newState?.automation) {
      newState.automation = {} as ContactsDetails;
    }
    newState.automation[e.target.name] = e.target.value;
    setState(newState);
  };

  const onDefaultContactChange = e => {
    const newState = cloneDeep(state);
    if (!newState?.defaultSupport) {
      newState.defaultSupport = {} as ContactsDetails;
    }
    newState.defaultSupport[e.target.name] = e.target.value;
    setState(newState);
  };

  const onSave = async() => {
    try {
      brandContactDetails.set('/contacts', state);
      setSaving(true);
      await patchBrand(accessToken, brandContactDetails, brand.brandId, false);
      setSaving(false);
      setEdit(false);
      dispatch(onResetEditAction(false));
      if (state) {
        dispatch(onPatchBrandContacts(state));
      }
      dispatch(showSnackbar(t('statusMessages.brandEditedSuccessfully'), undefined, 'success'));
    } catch (err) {
      setSaving(false);
      dispatch(showSnackbar(err || t('statusMessages.errorInEditingBrand'), undefined, 'danger'));
    }
  };

  const onEdit = () => {
    setEdit(true);
    dispatch(onResetEditAction(true));
  };

  const onCancel = () => {
    setEdit(false);
    setState(brand?.contacts || {} as Contacts);
    dispatch(onResetEditAction(false));
  };

  return saving ? <Loading/>
    : <div>
      <InlineEditHeader
        onEdit={onEdit}
        onSave={onSave}
        onCancel ={onCancel}
        disableSave = {!(isValid(state?.automation?.address) && isValid(state?.defaultSupport?.address))}
        edit = {edit}
        title={t('brand.contacts.title')}
      />
      <div className="row">
        <div className="col-md-6">
          <h6>{t('brand.contacts.defaultSupport')}</h6>
          <TextField
            name={'name'}
            label={t('brand.contacts.name')}
            value={state?.defaultSupport?.name}
            disabled={!edit}
            onChange={onDefaultContactChange}
          />
          <TextField
            name ={'address'}
            label={t('brand.contacts.address')}
            value={state?.defaultSupport?.address}
            disabled={!edit}
            type='email'
            status={!state?.defaultSupport?.address ? '' : isValid(state.defaultSupport.address) ? 'success' : 'error'}
            onChange={onDefaultContactChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h6>{t('brand.contacts.automation')}</h6>
          <TextField
            name={'name'}
            label={t('brand.contacts.name')}
            value={state?.automation?.name}
            disabled={!edit}
            onChange={onAutomationContactChange}
          />
          <TextField
            name={'address'}
            label={t('brand.contacts.address')}
            value={state?.automation?.address}
            disabled={!edit}
            type='email'
            status={!state?.automation?.address ? '' : isValid(state.automation.address) ? 'success' : 'error'}
            onChange={onAutomationContactChange}
          />
        </div>
      </div>
    </div>;
};
