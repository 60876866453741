import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandDetailsDto } from '../../components/hooks/commonTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { Account, setAccountBrand } from '../../services/accounts';
import { Select, Button } from '@cimpress/react-components';
import { onPatchAccountBrand } from '../../store/accounts/actions';

export interface BrandAccountsProps {
    brand: BrandDetailsDto|null;
}

const formatAccountName = (acc: Account) => `${acc.name} (${acc.accountId})`;

export const BrandAccounts: React.FC<BrandAccountsProps> = ({ brand }) => {
  const { t } = useTranslation();
  const { auth, accounts } = useSelector((state: AppState) => state);
  const { accessToken } = auth;
  const [selectedAccount, setSelectedAccount] = useState<{ label: string; value: string } | null>(null);
  const [isPatchingBrand, setIsPatchingBrand] = useState<boolean>(false);
  const [showOverwriteWarning, setShowOverwriteWarning] = useState<boolean>(false);

  const dispatch = useDispatch();

  const accountsUsingBrand = useMemo(() => {
    if (accounts.accounts && brand) {
      return Object.values(accounts.accounts).filter(account => account.brandId === brand?.brandId);
    }
    return null;
  }, [accounts, brand]);

  const handleClickAddAccount = async () => {
    if (!selectedAccount || !brand) {
      return;
    }

    if (!showOverwriteWarning && accounts.accounts && accounts.accounts[selectedAccount.value].brandId) {
      setShowOverwriteWarning(true);
      return;
    }

    setShowOverwriteWarning(false);
    setIsPatchingBrand(true);
    try {
      await setAccountBrand(accessToken, selectedAccount.value, brand?.brandId);
      setIsPatchingBrand(false);
      setSelectedAccount(null);
    } catch {
      setIsPatchingBrand(false);
    }
    dispatch(onPatchAccountBrand(selectedAccount.value, brand?.brandId));
  };

  if (!brand) {
    return <>&nbsp;</>;
  }

  return <>
    <h4>{t('accounts.title')}</h4>
    <div>
      {accountsUsingBrand?.length
        ? <ul>
          {accountsUsingBrand?.map(acc => <li key={acc.accountId}>
            <a href={`https://accounts.cimpress.io/${acc.accountId}`}>{formatAccountName(acc)}</a>
          </li>)}
        </ul>
        : t('accounts.noAccountUsingBrand')
      }
    </div>
    <div className='row'>
      <div className='col-md-6'>
        <div className=''>
          <Select
            label='Accounts'
            value={selectedAccount}
            onChange={value => {
              setShowOverwriteWarning(false);
              if (value) {
                setSelectedAccount(value);
              } else {
                setSelectedAccount(null);
              }
            }}
            options={Object.values(accounts.accounts || {})
              .filter(acc => acc.brandId !== brand?.brandId)
              .map(acc => ({ label: formatAccountName(acc), value: acc.accountId }))}
            placeholder={t('accounts.selectAccountPlaceholder')}
            isDisabled={!accounts.accounts || isPatchingBrand}
          />
        </div>
        <div>
          <Button
            disabled={!selectedAccount || isPatchingBrand}
            onClick={handleClickAddAccount}>
            {showOverwriteWarning ? t('reallySave') : t('save')}
          </Button>
        </div>
        {showOverwriteWarning
    && accounts.accounts
    && selectedAccount
    && <div>
      <small>
        {<a href={accounts.accounts[selectedAccount.value].brandId}>This account is already associated with a different brand</a>}.
        Please confirm that you want to overwrite it.
      </small>
    </div>}
      </div>
    </div>
  </>;
};
