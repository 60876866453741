import { SnackbarActions } from '../storeActions';

export const showSnackbar = (message: string, details?: string, snackbarStatus?: 'danger' | 'info' | 'warning' | 'success' | undefined) => {
  return {
    type: SnackbarActions.SHOW_SNACKBAR,
    message,
    details,
    snackbarStatus: snackbarStatus || 'danger'
  };
};

export const hideSnackbar = () => {
  return {
    type: SnackbarActions.HIDE_SNACKBAR
  };
};

