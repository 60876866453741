import { TextField, Button, FlexBox, Modal } from '@cimpress/react-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { saveBrand } from '../services/brands';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store/store';
import { useHistory } from 'react-router-dom';
import { showSnackbar } from '../store/snackbar/actions';

export const CreateBrand: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { auth } = useSelector((state: AppState) => state);
  const { accessToken } = auth;
  const [brandName, setBrandName] = useState('');
  const [show, setShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  
  const brandNameChange = e => {
    setBrandName(e.target.value);
  };

  const onSaveClick = async () => {
    try {
      const brandDetails = await saveBrand(accessToken, brandName);
      dispatch(showSnackbar(t('statusMessages.brandAddedSuccessfully'), undefined, 'success'));
      history.push(`/brands/${brandDetails.brandId}`);
    } catch (err) {
      dispatch(showSnackbar(err || t('statusMessages.errorInCreatingBrand'), undefined, 'danger'));
    }
  };

  const closeModal = () => {
    setShow(false);
  };
 
  return <>
    <Button variant={'primary'} className='createBrandBtn' onClick={() => setShow(true)}>{t('createBrand')}</Button>
    <Modal
      show={show}
      onRequestHide={closeModal}
      title={t('createNewBrand')}
      closeButton={true}
      footer={(
        <FlexBox right>
          <Button size='lg' onClick={closeModal} style={{ marginRight: '10px' }}>{t('cancel')}</Button>
          <Button onClick ={onSaveClick} className='saveBrandsBtn' variant='primary' size='lg' disabled={!brandName}>
            {t('save')}
          </Button>
        </FlexBox>
      )}
    >
      <TextField
        label = {t('brands.name')}
        value = {brandName}
        onChange = {brandNameChange}
        required
      />
    </Modal>
  </>;
};
