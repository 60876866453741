import React from 'react';
import { Snackbar } from '@cimpress/react-components';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../store/store';
import { hideSnackbar } from '../store/snackbar/actions';

export const SnackbarNotification: React.FC = () => {
  const snackbar = useSelector((state: AppState) => state.snackbar);
  const dispatch = useDispatch();

  if (!snackbar.message) {
    return null;
  }

  return (
    <div>
      <Snackbar show={true} bsStyle={snackbar.snackbarStatus} delay={3000} onHideSnackbar={() => dispatch(hideSnackbar())}>
        <strong>{snackbar.message}</strong>
        {snackbar.details ? <p>{snackbar.details}</p> : null}
      </Snackbar>
    </div>
  );
};
