import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ItemDto } from '../../components/hooks/commonTypes';
import { getBrandById } from '../../services/brands';
import { AppState } from '../store';
import { BrandActions } from '../storeActions';

export const onLoadBrandsStart = () => ({ type: BrandActions.LOAD_START });
export const onLoadBrandsSuccess = (brand: ItemDto) => ({ type: BrandActions.LOAD_SUCCESS, brand });
export const onLoadBrandsFailure = (error: any) => ({ type: BrandActions.LOAD_FAILURE, error });
export const onPatchBrandName = (brandName: string) => ({ type: BrandActions.BRAND_PATCH_BRAND_NAME, brandName });
export const onPatchBrandHeader = (header: ItemDto['header']) => ({ type: BrandActions.BRAND_PATCH_HEADER, header });
export const onPatchBrandThemes = (themes: ItemDto['themes']) => ({ type: BrandActions.BRAND_PATCH_THEMES, themes });
export const onPatchBrandContacts = (contacts: ItemDto['contacts']) => ({ type: BrandActions.BRAND_PATCH_CONTACTS, contacts });
export const onResetBrand = () => ({ type: BrandActions.RESET_BRAND });
export const onResetEditAction = (value: boolean) => ({ type: BrandActions.RESET_EDIT_ACTION, value });

export const loadBrandsById = (accessToken: string, brandId: string): ThunkAction<void, AppState, void, AnyAction> => async dispatch => {
  try {
    dispatch(onLoadBrandsStart());
    const brandResponse = await getBrandById(accessToken, brandId);
  
    dispatch(onLoadBrandsSuccess(brandResponse));
  } catch (err) {
    dispatch(onLoadBrandsFailure(err));
  }
};
  
