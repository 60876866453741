import React from 'react';

export const NoImage: React.FC<NoImageProps> = props => {
  return <div className='noImage' style={{ width: props.width }}>
    <i className="fa fa-image fa-5x"
      style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', color: 'white',
        paddingTop: '5%' }}/>
  </div>;
};

interface NoImageProps{
    width?: string;
}
