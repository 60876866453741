import * as authActions from '../store/auth/actions';
// @ts-ignore
import { centralizedAuth } from '@cimpress/simple-auth-wrapper';
import jwt_decode from 'jwt-decode';
import { CombinedState, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AuthState } from '../store/auth/types';

export const auth = new centralizedAuth({
  clientID: process.env.REACT_APP_APPLICATION_CLIENT_ID,
  redirectRoute: '/',
  logoutRedirectUri: 'https://cimpress.io'
});

export const getUserAccountId = (): string|null => {
  const token = auth.getAccessToken();
  if (token) {
    const decoded = jwt_decode(token) as Record<string, any>;
    return decoded['https://claims.cimpress.io/account'] as string;
  }
  return null;
};

export class Auth {
  public readonly ssoAuth;
  private profile: {} = {};
  private readonly dispatch;
  private accessToken = '';

  constructor(dispatch: ThunkDispatch<CombinedState<{ auth: AuthState }>, void, AnyAction>) {
    this.dispatch = dispatch;
    this.ssoAuth = auth;

    this.ssoAuth.on('tokenExpired', async () => {
      // This is invoked each time the current token in localStorage becomes expired.
      // This will also be immediately invoked if the token in localStorage is already expired. (configurable via emitInitialTokenExpired)
      console.log('receivedTokenExpired');
      await this.login();
    });
  }

  get userId(): string {
    const content = jwt_decode(this.accessToken) as Record<string, any>;
    return content ? content.sub : '';
  }

  async fastSafeTokenAccess(): Promise<string|null> {
    if (this.accessToken) {
      return this.accessToken;
    }

    try {
      await this.login();
      return this.accessToken;
    } catch (e) {
      console.log('fastSafeTokenAccess', e);
      return null;
    }
  }

  get isLoggedIn() {
    return !!this.ssoAuth.isLoggedIn();
  }

  async login(): Promise<void> {
    try {
      await this.ssoAuth.ensureAuthentication({
        nextUri: window.location.pathname + window.location.search
      });
      this.profile = this.ssoAuth.getProfile();
      // HACK for FS's wrapper
      // @ts-ignore
      this.profile.user_id = this.profile.sub;
      this.accessToken = this.ssoAuth.getAccessToken();
      this.dispatch(authActions.onRefreshedProfile(this.profile));
      this.dispatch(authActions.onRefreshedToken(this.accessToken));
    } catch (e) {
      console.log('login failed', e);
      this.dispatch(authActions.onAuthFailure('Authentication unsuccessful.'));
    }
  }

  async logout() {
    await this.ssoAuth.logout();
    this.dispatch(authActions.onLogout());
  }
}
