import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Account, getAccounts } from '../../services/accounts';
import { AppState } from '../store';
import { AccountsActions } from '../storeActions';

export const onLoadAccountsStart = () => ({ type: AccountsActions.LOAD_START });
export const onLoadAccountsSuccess = (accounts: Account[]) => ({ type: AccountsActions.LOAD_SUCCESS, accounts });
export const onLoadAccountsFailure = (error: any) => ({ type: AccountsActions.LOAD_FAILURE, error });
export const onPatchAccountBrand = (accountId: string, brandId: string) => ({ type: AccountsActions.PATCH_ACCOUNT_BRAND_ID, accountId, brandId });

export const loadAccounts = (accessToken: string): ThunkAction<void, AppState, void, AnyAction> => async dispatch => {
  try {
    dispatch(onLoadAccountsStart());
    const accountsResponse = await getAccounts(accessToken, null);

    dispatch(onLoadAccountsSuccess(accountsResponse._embedded.item));
  } catch (err) {
    dispatch(onLoadAccountsFailure(err));
  }
};
