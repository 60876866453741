import React, { useEffect } from 'react';
// @ts-ignore
import Header from '@cimpress-technology/react-platform-header';
import { LoudspeakerIcon } from '@cimpress-technology/react-loudspeaker';
// @ts-ignore
import SettingsModal from '@cimpress-technology/react-platform-settings';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { getPreferredMcpLanguages } from 'cimpress-customizr';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from './store/auth/actions';
import { AppState } from './store/store';

function AppHeader() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { accessToken, profile } = useSelector((state: AppState) => state.auth);
  const isLoggedIn = !!accessToken;

  const fetchDefaultLanguageAndSet = () => {
    getPreferredMcpLanguages(accessToken)
      .then((mcpLanguages: any) => {
        const availableLangs = Object.keys(i18n.options.resources as object);
        const lang = mcpLanguages.filter((lng: any) => availableLangs.includes(lng.iso639_1))[0];
        if (lang) {
          i18n.changeLanguage(lang.iso639_1); // two-letter code
        } else {
          // eslint-disable-next-line no-console
          console.log(`Failed to parse language from Customizr settings: '${mcpLanguages}'`);
        }
      })
      .catch(() => {
        // automatically sets to default language initialized in i18n init function
      });
  };

  useEffect(() => {
    fetchDefaultLanguageAndSet();
  // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Header
        accessToken={accessToken}
        appTitle={
          <Link to={'/'}>
            <h2>Brand Manager</h2>
          </Link>
        }
        appLinks={[
          {
            id: 'contact',
            type: 'default',
            title: t('contactUs'),
            content: <a href={'https://trdlnk.cimpress.io/contactUs'} target={'_blank'} rel="noopener noreferrer">{t('contactUs')}</a>
          }, {
            id: 'settings',
            content: (
              <SettingsModal
                lang={i18n.language}
                authToken={accessToken || 'x'}
                supportedLanguages={Object.keys(i18n.options.resources as object)}
                canSave
                onSave={() => fetchDefaultLanguageAndSet()}
              />
            )
          }
        ]}
        isLoggedIn={isLoggedIn}
        profile={profile}
        onLogInClicked={() => dispatch(authActions.login())}
        onLogOutClicked={() => dispatch(authActions.logout())}
        useBranding={true}
        notifications={<LoudspeakerIcon
          accessToken={accessToken}
          channelIds={['brand-manager']}
          title={t('loudspeakerTitle')}
        />}
      />
    </div>
  );
}

export { AppHeader };
