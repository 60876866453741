import { SnackbarState, SnackbarActionTypes } from './types';
import { SnackbarActions } from '../storeActions';

const initialState: SnackbarState = {
  message: undefined,
  details: undefined,
  snackbarStatus: undefined
};

export function snackbarReducer(state = initialState, action: SnackbarActionTypes): SnackbarState {
  switch (action.type) {
    case SnackbarActions.SHOW_SNACKBAR:
      return {
        ...state,
        message: action.message,
        details: action.details,
        snackbarStatus: action.snackbarStatus
      };
    case SnackbarActions.HIDE_SNACKBAR:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
