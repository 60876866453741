import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store/store';
import { useParams } from 'react-router';
import { Card, Dropdown, Button, Alert, Breadcrumbs, BreadcrumbItem, NavTab, NavTabItem, InlineEdit } from '@cimpress/react-components';
import { Loading } from '../../components/loading';
import { useTranslation } from 'react-i18next';
import { DeleteBrandModal } from '../../components/deleteBrandModal';
import { deleteBrand, patchBrand } from '../../services/brands';
import { useHistory, Link } from 'react-router-dom';
import IconNavigationMenuVertical from '@cimpress-technology/react-streamline-icons/lib/IconNavigationMenuVertical';
import { BrandThemes } from './brandThemes';
import { BrandContacts } from './brandContacts';
import { BrandAccounts } from './brandAccounts';
import { BrandHeader } from './brandHeader';
import { Account } from '../../services/accounts';
import { showSnackbar } from '../../store/snackbar/actions';
import { loadBrandsById, onPatchBrandName, onResetBrand, onResetEditAction } from '../../store/brands/actions';

const PLATFORM_HEADER_TAB = 'platformHeader';
const ACCOUNTS_TAB = 'accounts';
const THEMES_TAB = 'themes';
const CONTACTS_TAB = 'contacts';

export const BrandDetails: React.FC<any> = () => {
  const { auth, accounts } = useSelector((state: AppState) => state);
  const { accessToken } = auth;
  const { id } = useParams<{id: string}>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading, brand, error, disableEditAction } = useSelector((state: AppState) => state.brand);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [savingBrandName, setSavingBrandName] = useState(false);
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(PLATFORM_HEADER_TAB);
  const brandDetailsMap = new Map();
  
  useEffect(() => {
    dispatch(loadBrandsById(accessToken, id));
    return () => { dispatch(onResetBrand()); };
  }, [accessToken, id, dispatch]);

  const saveBrandName = async e => {
    try {
      setSavingBrandName(true);
      brandDetailsMap.set('/brandName', e.value);
      await patchBrand(accessToken, brandDetailsMap, id, true);
      setSavingBrandName(false);
      dispatch(onPatchBrandName(e.value));
      dispatch(showSnackbar(t('statusMessages.brandEditedSuccessfully'), undefined, 'success'));
    } catch (err) {
      setSavingBrandName(false);
      dispatch(showSnackbar(err || t('statusMessages.errorInEditingBrand'), undefined, 'danger'));
    }
  };

  const removeBrand = () => setShow(true);

  const onCloseModal = () => setShow(false);

  const validateText = text => {
    if (!text) {
      return t('validBrandName');
    }
    return null;
  };

  // TODO: Simplify this!
  const brandsToAccounts = useMemo(() => {
    if (accounts.accounts) {
      return Object.values(accounts.accounts || {}).reduce<Record<string, Account[]>>((acc, account) => {
        if (!account.brandId) { return acc; }
        acc[account.brandId] = [...(acc[account.brandId] || []), account];
        return acc;
      }, {});
    }
    return null;
  }, [accounts]);

  const accountsCount = (((brandsToAccounts || {})[brand?.brandId || ''] || []).length) || 0;

  const onConfirmDeleteBrand = async () => {
    setShow(false);
    setDeleting(true);
    try {
      await deleteBrand(accessToken, id);
      dispatch(showSnackbar(t('statusMessages.brandDeletedSuccessfully'), undefined, 'success'));
      setDeleting(false);
      history.push('/');
    } catch (err) {
      setDeleting(false);
      dispatch(showSnackbar(err.toString() || t('statusMessages.errorDeletingBrand'), undefined, 'danger'));
    }
  };

  const onEditing = (editState: boolean) => {
    if (editState === true) {dispatch(onResetEditAction(true));} else {dispatch(onResetEditAction(false));}
  };

  return <div className='container'>
    <Breadcrumbs>
      <BreadcrumbItem>
        <Link to={'/'}>{t('breadcrumbs.home')}</Link>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <Link to={'/'}>{t('breadcrumbs.brands')}</Link>
      </BreadcrumbItem>
      <BreadcrumbItem active>
        {brand?.brandName}
      </BreadcrumbItem>
    </Breadcrumbs>
    <div className = 'container'>
      <Card>
        { loading || deleting || savingBrandName
          ? <Loading/>
          : error ? <Alert className= 'error' message={error}/> : null}
        {brand
    && <>
      <DeleteBrandModal
        show={show}
        onCancel={onCloseModal}
        onConfirm={onConfirmDeleteBrand}
      />
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1000 }} >
          <InlineEdit
            name='brandName'
            value = {brand.brandName}
            onSave={saveBrandName}
            validateInput={validateText}
            size='h2'
            style={{ width: '60%' }}
            disabled={disableEditAction}
            onEditStateChange={onEditing}
          />
        </div>
        <div style={{ marginLeft: '5px' }}>
          <Dropdown title={<IconNavigationMenuVertical/>} variant='simple' disabled={disableEditAction}>
            <Button disabled variant='anchor'>{t('makeCopy')}</Button>
            <Button disabled variant='anchor'>{t('preview')}</Button>
            <Button variant='anchor' onClick={removeBrand}>{t('delete')}</Button>
          </Dropdown>
        </div>
      </div>
      <hr/>
      <NavTab>
        <NavTabItem active={selectedTab === ACCOUNTS_TAB} disabled={disableEditAction}>
          <button disabled={disableEditAction} onClick={() => setSelectedTab(ACCOUNTS_TAB)}>{`${t('brandPage.accountsTab')} ${accountsCount >= 0 ? `(${accountsCount})` : ''}`}</button>
        </NavTabItem>
        <NavTabItem active={selectedTab === PLATFORM_HEADER_TAB} disabled={disableEditAction}>
          <button disabled={disableEditAction} onClick={() => setSelectedTab(PLATFORM_HEADER_TAB)}>{t('brandPage.platformHeaderTab')}</button>
        </NavTabItem>
        <NavTabItem active={selectedTab === THEMES_TAB} disabled={disableEditAction}>
          <button disabled={disableEditAction} onClick={() => setSelectedTab(THEMES_TAB)}>{t('brandPage.themesTab')}</button>
        </NavTabItem>
        <NavTabItem active={selectedTab === CONTACTS_TAB} disabled={disableEditAction}>
          <button disabled={disableEditAction} onClick={() => setSelectedTab(CONTACTS_TAB)}>{t('brandPage.contactsTab')}</button>
        </NavTabItem>
      </NavTab>

      <div>
        {(selectedTab === ACCOUNTS_TAB)
          ? <BrandAccounts brand={brand} />
          : selectedTab === PLATFORM_HEADER_TAB
            ? <BrandHeader brand={brand} />
            : selectedTab === THEMES_TAB
              ? <BrandThemes brand={brand} onChange={() => {
                // todo
              }} />
              : selectedTab === CONTACTS_TAB
                ? <BrandContacts brand={brand} />
                : 'o-o-o'}
      </div>
    </>}
      </Card>
    </div>
  </div>;
};
