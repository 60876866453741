import { BrandActionTypes, BrandState } from './types';
import { BrandActions } from '../storeActions';
import { cloneDeep } from 'lodash';
import { BrandDetailsDto } from '../../components/hooks/commonTypes';

const initialState: BrandState = {
  brand: null,
  loading: false,
  error: null,
  disableEditAction: false
};

export function brandReducer(state = initialState, action: BrandActionTypes): BrandState {
  switch (action.type) {
    case BrandActions.LOAD_START:
      return {
        ...state,
        error: null,
        loading: true
      };
    case BrandActions.LOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case BrandActions.LOAD_SUCCESS:
      return {
        ...state,
        brand: action.brand,
        error: null,
        loading: false
      };
    case BrandActions.BRAND_PATCH_BRAND_NAME: {
      const newState = cloneDeep(state);
      if (newState.brand?.brandName) {
        newState.brand.brandName = action.brandName;
      }
      return newState;
    }
    case BrandActions.BRAND_PATCH_HEADER: {
      const newState = cloneDeep(state);
      if (!newState.brand) {newState.brand = {} as BrandDetailsDto;}
      newState.brand.header = action.header;
      return newState;
    }
    case BrandActions.BRAND_PATCH_CONTACTS: {
      const newState = cloneDeep(state);
      if (!newState.brand) {newState.brand = {} as BrandDetailsDto;}
      newState.brand.contacts = action.contacts;
      return newState;
    }
    case BrandActions.BRAND_PATCH_THEMES: {
      const newState = cloneDeep(state);
      if (!newState.brand) {newState.brand = {} as BrandDetailsDto;}
      newState.brand.themes = action.themes;
      return newState;
    }
    case BrandActions.RESET_EDIT_ACTION:
      return {
        ...state,
        disableEditAction: action.value
      };
    case BrandActions.RESET_BRAND:
      return initialState;
    default:
      return state;
  }
}
