import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/store';
import { Alert, FlexBox } from '@cimpress/react-components';
import { useBrands } from '../../components/hooks/useBrands';
import { Loading } from '../../components/loading';
import { useUserName } from '../../components/hooks/useUserName';
import { Account } from '../../services/accounts';
import { BrandCard } from './brandCard';
import { CreateBrand } from '../createBrand';

export const BrandsPage: React.FC<any> = () => {
  const { auth, accounts } = useSelector((state: AppState) => state);
  const { accessToken } = auth;
  const [brands, loading, error] = useBrands(accessToken);
  const noOfCardPerRow = 3;
  const uniqueSubs = useMemo(() => {
    return brands && (brands.map(brand => brand.createdBy).filter((value, index, self) => self.indexOf(value) === index));
  }, [brands]);
 
  const [userNames, loadingUserNames, errorLoadingUserNames] = useUserName(accessToken, uniqueSubs);

  const brandsToAccounts = useMemo(() => {
    if (accounts.accounts && brands) {
      return Object.values(accounts.accounts || {}).reduce<Record<string, Account[]>>((acc, account) => {
        if (!account.brandId) { return acc; }
        acc[account.brandId] = [...(acc[account.brandId] || []), account];
        return acc;
      }, {});
    }
    return null;
  }, [accounts, brands]);

  const brandValues = brands;
  // array of N elements, where N is the number of rows needed
  const rows = brandValues && [...Array(Math.ceil(brandValues.length / noOfCardPerRow))];
  // chunk the brands into the array of rows
  const brandRows = rows && rows.map((row, idx) => brandValues.slice(idx * noOfCardPerRow, idx * noOfCardPerRow + noOfCardPerRow));
  // map the rows as div.row
  
  const content = brandRows && brandRows.map((row, idx) => {
    while (row.length < 3) {
      row.push(undefined as unknown as any);
    }
    return <div key={idx} className='row' style={{ marginBottom: '20px  ' }}>
      {row.map((brand, id) => (<BrandCard key={id} brand={brand} accountsCount={(((brandsToAccounts || {})[brand?.brandId || ''] || []).length) || 0} userNames={userNames}/>))}
    </div>;
  }
  );

  return loading || loadingUserNames ? <Loading/>
    : <>
      {error || errorLoadingUserNames
      // @ts-ignore
        ? <Alert className= 'error' message={error || errorLoadingUserNames?.message}/>
        : null
      }
      {brands?.length >= 0
        ? <div>
          <div style={{ marginBottom: '10px' }}>
            <FlexBox right>
              <CreateBrand/>
            </FlexBox>
          </div>
          {content}
        </div>
        : null}
    </>;
};

