import { useEffect, useState } from 'react';
import { getPrincipal } from 'coam-client';

export const useUserName = (accessToken: string, uniqueSubs: string[]) => {
  const [info, setInfo] = useState({
    userNames: null as unknown as Record<string, string>,
    loadingUserNames: false,
    errorLoadingUserNames: null
  });

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const eff = async () => {
      const userNameMap = {};
      try {
        // @ts-ignore
        setInfo({ userNames: {}, loadingUserNames: true, errorLoadingUserNames: null });
        if (uniqueSubs && uniqueSubs.length > 0) {
          await Promise.allSettled(uniqueSubs.map(async sub => {
            userNameMap[sub] = (await getPrincipal(accessToken, sub))?.profile?.name;
          }));
          setInfo({ userNames: userNameMap, loadingUserNames: false, errorLoadingUserNames: null });
        } else {
          setInfo({ userNames: {}, loadingUserNames: false, errorLoadingUserNames: null });
        }
      } catch (e) {
        setInfo({ userNames: {}, loadingUserNames: false, errorLoadingUserNames: e });
      }
    };
    eff();
  }, [accessToken, uniqueSubs]);
  
  return [info.userNames, info.loadingUserNames, info.errorLoadingUserNames] as const;
};
