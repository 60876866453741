/*
 * To anyone editing this file:
 * Please follow the convention of putting the namespace in the enums
 * Without this convention we might accidentally run into collisions
 */

export enum AuthActions {
  REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN',
  REFRESH_PROFILE = 'AUTH_REFRESH_PROFILE',
  LOGOUT = 'AUTH_LOGOUT',
  FAILURE = 'AUTH_FAILURE'
}

export enum AccountsActions {
  LOAD_START = 'ACCOUNTS_LOAD_START',
  LOAD_SUCCESS = 'ACCOUNTS_LOAD_SUCCESS',
  LOAD_FAILURE = 'ACCOUNTS_LOAD_FAILURE',
  PATCH_ACCOUNT_BRAND_ID = 'ACCOUNTS_PATCH_ACCOUNT_BRAND_ID',
}

export enum SnackbarActions {
  SHOW_SNACKBAR = 'SNACKBAR_SHOW_SNACKBAR',
  HIDE_SNACKBAR = 'SNACKBAR_HIDE_SNACKBAR',
}

export enum BrandActions {
    LOAD_START = 'BRANDS_LOAD_START',
    LOAD_SUCCESS = 'BRANDS_LOAD_SUCCESS',
    LOAD_FAILURE = 'BRANDS_LOAD_FAILURE',
    BRAND_PATCH_BRAND_NAME = 'BRAND_PATCH_BRAND_NAME',
    BRAND_PATCH_CONTACTS ='BRAND_PATCH_CONTACTS',
    BRAND_PATCH_THEMES ='BRAND_PATCH_THEMES',
    BRAND_PATCH_HEADER='BRAND_PATCH_HEADER',
    RESET_BRAND = 'RESET_BRAND',
    RESET_EDIT_ACTION = 'RESET_EDIT_ACTION'
}
