import { useEffect, useState } from 'react';
import { getBrands } from '../../services/brands';

import { ItemDto } from './commonTypes';

const useBrands = (accessToken: any, includes?: string, showArchived?: boolean) => {
  const [info, setInfo] = useState({
    brands: null as unknown as ItemDto[],
    loading: false,
    error: null
  });

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const eff = async () => {
      try {
        // @ts-ignore
        setInfo({ brands: null, loading: true, error: null });

        const data = (await getBrands(accessToken));

        setInfo({ brands: data, loading: false, error: null });
      } catch (e) {
        setInfo({ brands: [], loading: false, error: e });
      }
    };
    eff();
  }, [accessToken, includes, showArchived]);

  return [info.brands, info.loading, info.error] as const;
};

export {
  useBrands
};
