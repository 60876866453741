import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { authReducer } from './auth/reducers';
import * as AuthActions from './auth/actions';
import { accountsReducer } from './accounts/reducers';
import { snackbarReducer } from './snackbar/reducers';
import { brandReducer } from './brands/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  accounts: accountsReducer,
  snackbar: snackbarReducer,
  brand: brandReducer
});

export type AppState = ReturnType<typeof rootReducer>;

function configureStore() {
  const middlewares = [
    thunkMiddleware
  ];
  const middleWareEnhancer = applyMiddleware(...middlewares);
  
  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );
  
  /**
     * Bootstrapping store objects
     * store.dispatch is not compatible with redux-thunk, so we need to do this cast
     */
  (store.dispatch as ThunkDispatch<AppState, void, AnyAction>)(AuthActions.initializeAuth());
  
  return store;
}
  
export const store = configureStore();
