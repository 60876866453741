import { makeRequest, buildOptions, httpMethod } from './serviceHelpers';
import { SentryWrapper } from '@cimpress-technology/react-reporting-redux';
import get from 'lodash/get';

const endpointUrl: string = process.env.REACT_APP_BRANDING_API_URL || '';
export const getBrands = async (accessToken: string, include = '*', showArchived = false) => {
  // @ts-ignore
  const options = buildOptions({ hal: true, accessToken });
  return makeRequest(
    endpointUrl,
    `v1/brands?include=${include}&showArchived=${showArchived}&skip_cache=${Math.random()}`,
    options,
    'getting brands'
  ).then(response => {
    return get(response, '_embedded.item');
  }).catch(err => {
    const errorResponse = get(
      err,
      'response.detail',
      'Error getting brands'
    );
    SentryWrapper.reportError(err);
    throw errorResponse;
  });
};

export const getBrandById = async (
  accessToken: any,
  brandId: string,
  include = '*'
) => {
  // @ts-ignore
  const options = buildOptions({ accessToken });
  return makeRequest(
    endpointUrl,
    `/v1/brands/${brandId}?include=${include}`,
    options,
    'getting brands'
  )
    .then(response => {
      return response;
    })
    .catch(err => {
      const errorResponse = get(
        err,
        'response.detail',
        'Error getting brand'
      );
      SentryWrapper.reportError(err);
      throw errorResponse;
    });
};

export const saveBrand = async (accessToken: string, brandName: string) => {
  // @ts-ignore
  const options = buildOptions({ method: httpMethod.POST,
    body: JSON.stringify({ brandName: brandName }),
    accessToken });
  return makeRequest(
    endpointUrl,
    '/v1/brands',
    options,
    'saving brand'
  )
    .then(response => {
      return response;
    })
    .catch(err => {
      const errorResponse = get(
        err,
        'response.detail',
        'Error saving brand'
      );
      SentryWrapper.reportError(err);
      throw errorResponse;
    });
};
export const patchBrand = async (accessToken: string, patchMap: Map<string, any>, brandId: string, edit: boolean) => {
  const reqBody = [];
  const op = edit ? 'replace' : 'add';
  patchMap.forEach((key, value) => {
    reqBody.push({
      // @ts-ignore
      op: op,
      // @ts-ignore
      path: value,
      // @ts-ignore
      value: key
    });
  });
  const options = buildOptions({ method: 'PATCH', body: JSON.stringify(reqBody), hal: false, accessToken });
  return makeRequest(
    endpointUrl,
    `/v1/brands/${brandId}`,
    options,
    'patch brand details'
  )
    .then(response => {
      return response;
    })
    .catch(err => {
      const errorResponse = 'Brand is saved successfully but details are not updated!';
      SentryWrapper.reportError(err);
      throw errorResponse;
    });
};

export const deleteBrand = async (accessToken, id) => {
  // @ts-ignore
  const options = buildOptions({ method: httpMethod.DELETE, accessToken });
  return makeRequest(
    endpointUrl,
    `/v1/brands/${id}`,
    options,
    'deleting brands'
  ).then(response => {
    return response;
  }).catch(err => {
    const errorResponse = get(
      err,
      'response.detail',
      'Error deleting brand'
    );
    SentryWrapper.reportError(err);
    throw errorResponse;
  });
};
