import { FlexBox, Button } from '@cimpress/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../store/store';

interface InlineEditHeaderProps{
 onSave?: (e: any) => void;
 onEdit?: (e: any) => void;
 onCancel?: (e: any) => void;
 edit: boolean;
 title: string;
 disableSave?: boolean;
}

export const InlineEditHeader: React.FC<InlineEditHeaderProps> = ({ edit, title, onSave, onEdit, onCancel, disableSave = false }) => {
  const { t } = useTranslation();
  const { disableEditAction } = useSelector((state: AppState) => state.brand);

  return <div style={{ display: 'flex', marginBottom: '2%' }}>
    <h4>{title}</h4>
    <FlexBox right>
      { edit ? <> <Button onClick= {onCancel} style={{ marginRight: '1%' }}>{t('cancel')}</Button>
        <Button disabled = { disableSave } onClick={onSave}>{t('save')}</Button></>
        : <Button disabled = {disableEditAction} onClick= {onEdit}>{t('edit')}</Button> }
    </FlexBox>
  </div>;
};
