import React from 'react';
import { BrandsPage } from './brands';

const Home: React.FC<HomeProps> = props => {
  return (
    <div className='container'>
      <BrandsPage {...props}/>
    </div>
  );
};

export { Home };

interface HomeProps {
  match: {
    params: {
      id?: string;
    };
  };
  location: {
    search: string;
  };
}

