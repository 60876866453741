import React from 'react';
import { shapes } from '@cimpress/react-components';

const spinnerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export interface LoadingProps {
  fullPage?: boolean;
}

const Loading: React.FC<LoadingProps> = props => {
  const { fullPage } = props;
  const { Spinner } = shapes;

  return fullPage
    ? <Spinner />
    : <div style={spinnerStyle}>
      <Spinner />
    </div>
  ;
};

export {
  Loading
};
