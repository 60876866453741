import { Tag, Label } from '@cimpress/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface BrandUsageLabelProps {
  count: number;
}

export const BrandUsageLabel: React.FC<BrandUsageLabelProps> = props => {
  const { t } = useTranslation();

  if (props.count === 0) {
    return <Tag
      value={t('accounts.numberUsingBrand', { count: props.count })}
    />;
  }

  return <Label
    status="info"
    text={t('accounts.numberUsingBrand', { count: props.count })}
  />;
};
